.meet-the-team {
    .nav-pills {
        .nav-link-team {
            background: transparent;
            font-size: 1.5rem;
            font-weight: 700;
            text-align: left;
            padding-left: 0;
            color: #c5c4ce;
            border-radius: 0;
            border: 0;
            padding-bottom: 0;
            @include media-breakpoint-up(md) {
                font-size: 2rem;
                padding-bottom: 1rem;
            }
        }
        .nav-link-team.active {
            background: transparent;
            border-bottom: 5px solid $primary;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            color: $text-color;
        }
        .show {
            .nav-link-team {
                background: transparent;
                border-bottom: 5px solid $primary;
            }
        }
    }
    .tab-content {
        .biog {
            font-size: 0.875rem;
            @include media-breakpoint-up(md) {
                font-size: 1.125rem;
            }
            .role {
                font-weight: 700;
                font-size: 1rem;
                text-transform: uppercase;
                margin-bottom: 1rem;
                @include media-breakpoint-up(md) {
                    font-size: 1.5rem;
                }
            }
        }
        ul.contact-list {
            margin-top: 1rem;
            @include media-breakpoint-up(md) {
                margin-top: 2rem;
            }
            li {
                margin-right: 1rem;
                a {
                    bottom: 0;
                    position: relative;
                    display: inline-block;
                    transition: bottom 0.3s ease,
                                color 0.3s ease;
                    &:hover {
                        bottom: 5px;
                        color: $text-color;
                    }
                }
            }
        }
    }
}
.portrait-image {
    background-color: #333;
    border-radius: 500px;
    overflow: hidden;
}
.portrait-image img {
  animation: kenburns 20s infinite;
  border-radius: 500px;
}
