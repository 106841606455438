// Body
$body-bg: #26262B;

$offset-value: 100px;

// Typography
$font-family-sans-serif: azo-sans-web, sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

// Colors
$primary: #F36F21;
$primary-light: #FCC069;
$secondary: #D71921;
$secondary-light: #ED6369;
$secondary-dark: #8E0F15;
$light-grey: #F8F8F8;
$grey: #26262B;
$mid-grey: #575665;
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$text-color: #2D2D2D;
$white: #ffffff;
$black: #000000;
$footer: #130C0D;
$difficulty-hard: $red;
$difficulty-medium: $orange;
$difficulty-easy: $green;


$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;


$input-color: $primary;
$input-background: #F2F2F2;
$input-placeholder-color: #b5b5b5 !default;

$headings-margin-bottom: 2rem;

$blog-item-padding: 2rem;

$alpha: .5;

$border-radius: 8px;
$border-radius-right: 0 8px 8px 0;
$border-radius-bottom: 0 0 8px 8px;

$filter-height: 80px;
$filter-height-small: 70px;

@mixin grad {
  background: rgb(235,102,8);
  background: -moz-linear-gradient(135deg, rgba(235,102,8,1) 0%, rgba(209,10,17,1) 100%);
  background: -webkit-linear-gradient(135deg, rgba(235,102,8,1) 0%, rgba(209,10,17,1) 100%);
  background: linear-gradient(135deg, rgba(235,102,8,1) 0%, rgba(209,10,17,1) 100%);
}
@mixin grad-trans {
  background: rgb(235,102,8);
  background: -moz-linear-gradient(135deg, rgba(235,102,8,0.5) 0%, rgba(209,10,17,0.5) 100%);
  background: -webkit-linear-gradient(135deg, rgba(235,102,8,0.5) 0%, rgba(209,10,17,0.5) 100%);
  background: linear-gradient(135deg, rgba(235,102,8,0.5) 0%, rgba(209,10,17,0.5) 100%);
}
@mixin grad-dark {
  background: rgb(89,88,101);
  background: -moz-linear-gradient(45deg, rgba(89,88,101,1) 0%, rgba(15,9,10,1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(89,88,101,1) 0%, rgba(15,9,10,1) 100%);
  background: linear-gradient(45deg, rgba(89,88,101,1) 0%, rgba(15,9,10,1) 100%);
}
@mixin grad-dark-trans {
  background: rgb(38,38,43);
  background: -moz-linear-gradient(45deg, rgba(38,38,43,0.6) 60%, rgba(19,12,13,0.6) 100%);
  background: -webkit-linear-gradient(45deg, rgba(38,38,43,0.6) 60%, rgba(19,12,13,0.6) 100%);
  background: linear-gradient(45deg, rgba(38,38,43,0.6) 60%, rgba(19,12,13,0.6) 100%);
}
@mixin grad-mid {
  background: rgb(89,88,101);
  background: -moz-linear-gradient(45deg, rgba(89,88,101,1) 0%, rgba(15,9,10,1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(89,88,101,1) 0%, rgba(15,9,10,1) 100%);
  background: linear-gradient(45deg, rgba(89,88,101,1) 0%, rgba(15,9,10,1) 100%);
}
@mixin grad-mid-trans {
  background: rgb(89,88,101);
  background: -moz-linear-gradient(45deg, rgba(89,88,101,0.5) 0%, rgba(15,9,10,0.5) 100%);
  background: -webkit-linear-gradient(45deg, rgba(89,88,101,0.5) 0%, rgba(15,9,10,0.5) 100%);
  background: linear-gradient(45deg, rgba(89,88,101,0.5) 0%, rgba(15,9,10,0.5) 100%);
}
@mixin grad-grey {
  background: rgb(219,219,219);
  background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(219,219,219,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(219,219,219,1) 100%);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(219,219,219,1) 100%);
}
@mixin grad-pg {
  background: rgb(61,49,91);
  background: -moz-linear-gradient(45deg,  rgba(61,49,91,1) 0%, rgba(0,128,69,1) 100%);
  background: -webkit-linear-gradient(45deg,  rgba(61,49,91,1) 0%,rgba(0,128,69,1) 100%);
  background: linear-gradient(45deg,  rgba(61,49,91,1) 0%,rgba(0,128,69,1) 100%);
}
@mixin grad-pl {
  background: rgb(148,135,178);
  background: -moz-linear-gradient(45deg,  rgba(148,135,178,1) 0%, rgba(61,49,91,1) 100%);
  background: -webkit-linear-gradient(45deg,  rgba(148,135,178,1) 0%,rgba(61,49,91,1) 100%);
  background: linear-gradient(45deg,  rgba(148,135,178,1) 0%,rgba(61,49,91,1) 100%);
}
@mixin grad-pl-alt {
  background: rgba(61,49,91,1);
  background: -moz-linear-gradient(45deg,  rgba(61,49,91,1) 0%, rgba(148,135,178,1) 100%);
  background: -webkit-linear-gradient(45deg,  rgba(61,49,91,1) 0%,rgba(148,135,178,1) 100%);
  background: linear-gradient(45deg,  rgba(61,49,91,1) 0%,rgba(148,135,178,1) 100%);
}
@mixin grad-pd {
  background: rgb(32,32,48);
  background: -moz-linear-gradient(45deg,  rgba(32,32,48,1) 0%, rgba(61,49,91,1) 100%);
  background: -webkit-linear-gradient(45deg,  rgba(32,32,48,1) 0%,rgba(61,49,91,1) 100%);
  background: linear-gradient(45deg,  rgba(32,32,48,1) 0%,rgba(61,49,91,1) 100%);
}
@mixin grad-home {
  background: rgb(96,170,69);
  background: -moz-linear-gradient(45deg,  rgba(96,170,69,1) 0%, rgba(212,216,71,1) 100%);
  background: -webkit-linear-gradient(45deg,  rgba(96,170,69,1) 0%,rgba(212,216,71,1) 100%);
  background: linear-gradient(45deg,  rgba(96,170,69,1) 0%,rgba(212,216,71,1) 100%);
}
@mixin grad-home-vert {
  background: rgb(96,170,69);
  background: -moz-linear-gradient(top,  rgba(96,170,69,1) 0%, rgba(212,216,71,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(96,170,69,1) 0%,rgba(212,216,71,1) 100%);
  background: linear-gradient(to bottom,  rgba(96,170,69,1) 0%,rgba(212,216,71,1) 100%);
}
@mixin grad-outdoor {
  background: rgb(233,159,77);
  background: -moz-linear-gradient(45deg,  rgba(233,159,77,1) 0%, rgba(222,110,60,1) 100%);
  background: -webkit-linear-gradient(45deg,  rgba(233,159,77,1) 0%,rgba(222,110,60,1) 100%);
  background: linear-gradient(45deg,  rgba(233,159,77,1) 0%,rgba(222,110,60,1) 100%);
}
@mixin grad-outdoor-vert {
  background: rrgb(233,159,77);
  background: -moz-linear-gradient(top,  rgba(233,159,77,1) 0%, rgba(222,110,60,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(233,159,77,1) 0%,rgba(222,110,60,1) 100%);
  background: linear-gradient(to bottom,  rgba(233,159,77,1) 0%,rgba(222,110,60,1) 100%);
}
@mixin shadow {
  -webkit-box-shadow: 0px 0px 50px -3px rgba(0,0,0,0.20);
  -moz-box-shadow: 0px 0px 50px -3px rgba(0,0,0,0.20);
  box-shadow: 0px 0px 50px -3px rgba(0,0,0,0.20);
}
@mixin transition {
    transition: background 0.3s ease,
                   opacity 0.3s ease,
                     color 0.3s ease,
                      left 0.3s ease;
}

@mixin boxshadow {
  -webkit-box-shadow: 10px 10px 30px 10px rgba(0,0,0,0.2);
  box-shadow: 10px 10px 30px 10px rgba(0,0,0,0.2);

}

$grid-breakpoints: (
  xs: 0,
  ms: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
  xxxl: 1900px
);

$container-max-widths: (
  ms: 375px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1410px,
  xxxl: 1750px
);

$cards-per-line: (
    xs: 1,
    ms: 1,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 3,
    xxl: 3,
    xxxl: 3
);

$two-cards-per-line: (
    xs: 1,
    ms: 1,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2,
    xxl: 2,
    xxxl: 3
);

$three-cards-per-line: (
    xs: 1,
    ms: 1,
    sm: 1,
    md: 2,
    lg: 2,
    xl: 3,
    xxl: 3,
    xxxl: 3
);

$four-cards-per-line: (
    xs: 1,
    ms: 1,
    sm: 2,
    md: 2,
    lg: 3,
    xl: 3,
    xxl: 4,
    xxxl: 4
);

// colors

$color-1: #25283D; // YANKEES BLUE
$color-2: #8F3985; // PLUM
$color-3: #ffffff; // WHITE
$color-4: #ECF0F1; // ANTI-FLASH WHITE

// fonts

$primary-font: azo-sans-web, sans-serif;
$secondary-font: azo-sans-web, sans-serif;

// header size

$header-height-small: 60px;
$header-height: 80px;

$secondary-nav-height-small: 50px;
$secondary-nav-height: 70px;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5
);