.map-size {
    height: 35vw;
    @include media-breakpoint-down(lg) {
        height: 50vw;
    }
    @include media-breakpoint-down(ms) {
        height: 100vw;
    }
}
.local-area-map-details {
    padding: 5rem;
    font-size: 1.5rem;
    .sub-title {
        color: $primary;
        opacity: 1;
    }
    h3 {
        color: $text-color;
    }
}
.map-container {
    position: relative;
    .map-responsive {
        padding-bottom: 35vw;
        @include media-breakpoint-down(lg) {
            padding-bottom: 50vw;
        }
        @include media-breakpoint-down(ms) {
            padding-bottom: 100vw;
        }
    }
}
.map-responsive {
    overflow:hidden;
    padding-bottom:100%;
    position:relative;
    height:0;
    // @include media-breakpoint-down(ms) {
    //     padding-bottom: 114vw;
    // }
    @include media-breakpoint-up(xxl) {
        padding-bottom:56.25%;
    }
    iframe {
        left:0;
        top:0;
        height:100%;
        width:100%;
        position:absolute;
        -webkit-filter: grayscale(100%);
               -moz-filter: grayscale(100%);
                 -o-filter: grayscale(100%);
                -ms-filter: grayscale(100%);
                    filter: grayscale(100%);
    }
}
.gm-style img {
    @include media-breakpoint-down(ms) {
        max-width: 100px;
    }
}
.local-area-map {
    position: relative;
    .icon-area {
        width: 5rem;
        height: 5rem;
        background: $primary;
        position: absolute;
        text-align: center;
        color: $white;
        font-size: 2.5rem;
        top: 0;
        right: 0;
        padding: 0.5rem;
        z-index: 2;
        i {
            width: 50%;
            margin: auto;
            position: absolute;
            top: 50%; left: 50%;
            -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
        }
    }
}
ul.local-area-list {
    @include media-breakpoint-down(xl) {
        padding: 5vw;
    }
    @include media-breakpoint-down(md) {
        margin: 6rem 0;
        padding: 0 5rem;
    }
    @include media-breakpoint-down(ms) {
        margin: 3rem 0;
    }
    li {
        list-style: none;
        a {
            @include small-caps;
            color: $text-color;
            font-weight: 600;
            left: 0;
            transition: left 0.3s ease;
            position: relative;
            @include media-breakpoint-down(ms) {
                font-size: 1.25rem;
            }
            &:hover {
                text-decoration: none;
                left: 5px;
            }
            i {
                width: 3rem;
                text-align: center;
                color: $primary;
            }
            &.active {
                color: $primary;
            }
        }
    }
}
.contact-map {
    bottom: 0;
    z-index: 2;
    background: transparent;
    color: $white;
    // color: $text-color;
    @include media-breakpoint-up(md) {
        @include grad-dark;
        margin-bottom: 0;
    }
    .details {
        background-image: url('/images/albion-sign.jpeg');
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        &::after {
            background: linear-gradient(45deg, rgba(38, 38, 43, 0.6) 75%, rgba(19, 12, 13, 0.6) 100%);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        & > :first-child {
            z-index: 1;
        }

        img {
            margin-bottom: 1.5rem;
            height: 50px;
            @include media-breakpoint-up(lg) {
                height: 66px;
            }
        }
        padding: 3rem 1rem;
        @include media-breakpoint-up(lg) {
            padding: 6rem 2rem;
        }
        @include media-breakpoint-up(xl) {
            h3 {
               font-size: 2rem;
            }
            font-size: 1.25rem;
        }
        @include media-breakpoint-up(xxl) {
            padding: 0 6rem;
        }
        a {
            color: $white;
            text-decoration: none;
        }
    }
    .address,
    .email,
    .tel {
        position: relative;
        &:before {
            content: '';
            font-family: "Font Awesome 5 Pro";
            position: absolute;
            left: -30px;
            font-size: 1.125rem;
            font-weight: 400;
            color: $primary;
            @include media-breakpoint-down(xl) {
                font-size: 1rem;
                left: -20px;
            }
        }
    }
    .address {
        margin-bottom: 1.5rem;
    }
    .email {
        margin-left: 1rem;
        position: relative;
        a {
            color: $white;
            text-decoration: none;
            left: 0;
            position: relative;
            transition: left 0.3s ease,
                        color 0.3s ease;
            &:hover {
                color: $primary;
                text-decoration: none;
                left: 5px;
            }
        }
        &:before {
            content: '\f0e0';
            font-family: "Font Awesome 5 Pro";
        }
    }
    .tel {
        margin-left: 1rem;
        position: relative;
        a {
            color: $white;
            text-decoration: none;
            left: 0;
            position: relative;
            transition: left 0.3s ease,
                        color 0.3s ease;
            &:hover {
                color: $primary;
                text-decoration: none;
                left: 5px;
            }
        }
        &:before {
            content: '\f879';
            font-family: "Font Awesome 5 Pro";
        }
    }
}
.client-map {
    .snippet {
        font-size: 0.75rem;
        @include media-breakpoint-up(md) {
            font-size: 0.75rem;
            margin-bottom: 3rem;
        }
        @include media-breakpoint-up(lg) {
            font-size: 0.75rem;
        }
        @include media-breakpoint-up(xl) {
            font-size: 1.25rem;
        }
        h2 {
            margin-bottom: 0.75rem;
        }
    }
    .map-animation-height {
        height: inherit;
        @include media-breakpoint-up(md) {
            height: 50vw;
        }
    }
    .map-animation {
        position: static;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        @include media-breakpoint-up(md) {
            position: absolute;
        }
    }
}