.banner-casestudy,
.banner {
    .banner-copy-home {
        min-height: 100vh;
        padding-bottom: 4rem;
        padding-top: 4rem;

        @include media-breakpoint-up(md) {
            padding-bottom: 8rem;
            padding-top: 8rem;
        }
    }
    .banner-copy {

    }
    .caption {
        position: relative;
        z-index: 2;
        .snippet {
            font-size: 1rem;
            @include media-breakpoint-up(md) {
                font-size: 1.25rem;
            }

            ul.tinymce {
                li {
                    background-position: 0px 8px;
                }
            }
        }
    }
    .offset-img-big {
        position: fixed;
        right: -298px;
        top: 70px;
        @include media-breakpoint-up(lg) {
            right: -690px;
            top: 0;
        }
        img {
            @include media-breakpoint-down(lg) {
                height: 620px;
            }
        }
    }
    .offset-img-small {
        position: fixed;
        right: -123px;
        top: 246px;
        @include media-breakpoint-up(lg) {
            right: -218px;
            top: 500px;
        }
        img {
            @include media-breakpoint-down(lg) {
                height: 268px;
            }
        }
    }
    .banner-img-big {
        position: fixed;
        right: -298px;
        top: 70px;
        @include media-breakpoint-up(lg) {
            right: 1300px;
            top: 0;
            img {
                height: 800px;
            }
        }
        img {
            @include media-breakpoint-down(lg) {
                height: 620px;
            }
        }
    }
    .banner-img-med {
        position: fixed;
        right: 290px;
        top: 80px;
        img {
            height: 150px;
        }
        @include media-breakpoint-up(sm) {
            right: 450px;
            top: 250px;
            img {
                height: 120px;
            }
        }
        @include media-breakpoint-up(lg) {
            right: 370px;
            top: 0;
            img {
                height: 510px;
            }
        }
    }
    .banner-img-small {
        position: fixed;
        right: -123px;
        top: 246px;
        @include media-breakpoint-up(lg) {
            right: -218px;
            top: 500px;
        }
        img {
            @include media-breakpoint-down(lg) {
                height: 268px;
            }
        }
    }
    .banner-img-tiny {
        position: fixed;
        right: 219px;
        top: 496px;
        img {
            height: 109px;
        }
        @include media-breakpoint-up(sm) {
            right: 500px;
            top: 596px;
            img {
                height: 400px;
            }
        }
        @include media-breakpoint-up(lg) {
            right: -28px;
            top: 697px;
            img {
                height: 468px;
            }
        }
    }
}
.banner-more-text {
    .caption {
        .snippet {
            font-size: 1rem;
            @include media-breakpoint-up(sm) {
                font-size: 1.25rem;
            }
        }
    }
    .smartersuccess {
        position: absolute;
        top: 5px;
        right: 0;
        height: 15px;
        @include media-breakpoint-up(sm) {
            top: 7px;
            height: 20px;
        }
        @include media-breakpoint-up(md) {
            top: 10px;
            height: 20px;
        }
        @include media-breakpoint-up(xl) {
            height: 30px;
        }
    }
}
.banner-casestudy {
    .banner-copy {
        z-index: 1;
        .company-logo,
        .caption {
            margin-bottom: 3rem;
            @include media-breakpoint-up(md) {
                margin-bottom: 6rem;
            }
        }
        .company-logo {
            @include media-breakpoint-up(sm) {
                padding: 0 4rem;
            }
            @include media-breakpoint-up(md) {
                padding: 0 1rem;
            }
            @include media-breakpoint-up(lg) {
                padding: 0 3rem;
            }
            @include media-breakpoint-up(xl) {
                padding: 0 4rem;
            }
            @include media-breakpoint-up(xxl) {
                padding: 0 6rem;
            }
        }
        .snippet {
            @include media-breakpoint-up(md) {
                font-size: 1.25rem;
            }
        }
        .caption {
            border-left: 0;
            padding-left: 0;
            @include media-breakpoint-up(md) {
                padding-left: 3rem;
                border-left: 1px solid $white;
            }
            .snippet {
                @include media-breakpoint-up(md) {
                    font-size: 1.25rem !important;
                }
                // @include media-breakpoint-up(xl) {
                //     font-size: 1.475rem;
                // }
            }
        }

    }
}
.small-banner {
    &.cd-hero,
    .banner-copy {
    }
    .caption {
        position: relative;
        z-index: 2;
        .snippet {
            font-size: 1rem;
            @include media-breakpoint-up(md) {
                font-size: 1.25rem;
            }

            // @include media-breakpoint-up(lg) {
            //     font-size: 1.5rem;
            // }
        }
    }
    .offset-img-big {
        position: fixed;
        right: -298px;
        top: 70px;
        @include media-breakpoint-up(lg) {
            right: -690px;
            top: 0;
        }
        img {
            @include media-breakpoint-down(lg) {
                height: 620px;
            }
        }
    }
    .offset-img-small {
        position: fixed;
        right: -123px;
        top: 246px;
        @include media-breakpoint-up(lg) {
            right: -218px;
            top: 500px;
        }
        img {
            @include media-breakpoint-down(lg) {
                height: 268px;
            }
        }
    }
    .banner-img-big {
        position: fixed;
        right: -298px;
        top: 70px;
        @include media-breakpoint-up(lg) {
            right: 1300px;
            top: 0;
            img {
                height: 800px;
            }
        }
        img {
            @include media-breakpoint-down(lg) {
                height: 620px;
            }
        }
    }
    .banner-img-med {
        position: fixed;
        right: 290px;
        top: 80px;
        img {
            height: 150px;
        }
        @include media-breakpoint-up(sm) {
            right: 450px;
            top: 250px;
            img {
                height: 120px;
            }
        }
        @include media-breakpoint-up(lg) {
            right: 370px;
            top: 0;
            img {
                height: 510px;
            }
        }
    }
    .banner-img-small {
        position: fixed;
        right: -123px;
        top: 246px;
        @include media-breakpoint-up(lg) {
            right: -218px;
            top: 500px;
        }
        img {
            @include media-breakpoint-down(lg) {
                height: 268px;
            }
        }
    }
    .banner-img-tiny {
        position: fixed;
        right: 219px;
        top: 496px;
        img {
            height: 109px;
        }
        @include media-breakpoint-up(sm) {
            right: 500px;
            top: 596px;
            img {
                height: 400px;
            }
        }
        @include media-breakpoint-up(lg) {
            right: -28px;
            top: 697px;
            img {
                height: 468px;
            }
        }
    }
}
.no-banner {
    height: 60px;
    padding: 0 !important;
    background: $grey;
    @include media-breakpoint-up(lg) {
        height: 104px;
        background: $grey;
    }
}
.rotate-img-200 {
  animation: rotation 200s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate-img-100-reverse {
  animation: rotation-reverse 100s infinite linear;
}
@keyframes rotation-reverse  {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
.more-info {
    position: relative;
    z-index: 2;
    padding-bottom: 5rem;
    &.no-before {
        li {
            &:before {
                display: none;
            }
        }
    }
    ul {
        li {
          font-size: 1.35rem;
          list-style: none;
          padding-left: 40px;
          position: relative;
          margin-bottom: 0.75rem;
          background-position: 0px 11px;
          &:before {
            content: "";
            display: inline-flex;
            height: 20px;
            width: 20px;
            left: -0px;
            background-image: url('/images/orange-circle.svg');
            background-position: center;
            background-size: contain;
            top: 10px;
            position: absolute;
          }
        }
    }
}
.author-details {
    margin-top: 3rem;
    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }
    .image img {
        border-radius: 50%;
    }
    .author-title {
        font-size: 1.25rem;
        font-weight: 700;
        @include media-breakpoint-up(md) {
            font-size: 2rem;
        }
    }
    ul.contact-list {
        margin-top: 0;
        @include media-breakpoint-up(md) {
            margin-top: 1rem;
        }
        li {
            margin-right: 1rem;
            a {
                font-size: 1rem;
                @include media-breakpoint-up(md) {
                    font-size: 1.5rem;
                }
            }
        }
    }
}
.small-banner-copy,
.blog-banner-copy {
    // padding: 8rem 0 3rem;
    // padding-top: 8rem;
    // @include media-breakpoint-up(sm) {
    //     padding: 8rem 0;
    //     padding-top: calc(8rem + 80px);
    // }
    .caption {
        h1 {
            font-size: 1.5rem;
            @include media-breakpoint-up(sm) {
                font-size: 2rem;
            }
        }
    }
}
.blog-banner-details {
    @include small-caps;
    @include media-breakpoint-down(sm) {
        font-size: 0.5rem;
    }
    .difficulty-level {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 50%;
        vertical-align: top;
        @include media-breakpoint-up(md) {
            width: 20px;
            height: 20px;
        }
        &.hard {
            background-color: $difficulty-hard;
        }
        &.medium {
            background-color: $difficulty-medium;
        }
        &.easy {
            background-color: $difficulty-easy;
        }
    }
}
/******** BOUNCING ARROW ******/
.arrow{
    display: none;
    position: absolute;
    z-index: 2;
    bottom: 2rem;
    left: 50%;
    margin-left:-20px;
    width: 50px;
    height: 50px;
    background: url(/images/mouse-arrow.svg) no-repeat center center;
    background-size: contain;
    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.bounce {
    animation: bounce 2s infinite;
}
.anchor-gallery,
.anchor-calculators,
.anchor-plans,
.anchor-local-area {
    display: block;
    position: relative;
    top: -80px;
    visibility: hidden;
}
.anchor-house-spec {
    display: block;
    position: relative;
    top: -130px;
    visibility: hidden;
}
.anchor-site-plan {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}
.anchor-top-content {
    display: block;
    position: relative;
    visibility: hidden;
    top: -100px;
    &.no-offset {
        top: 0;
    }
}
.anchor-top-content-alt {
    display: block;
    position: relative;
    visibility: hidden;
    top: -67px;
    &.no-offset {
        top: 0;
    }
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .banner .caption .snippet {
        //font-size: 1.5rem;
    }
}