.enquiry {
    padding-top: 3rem;
    @include media-breakpoint-up(md) {
        padding-top: 5rem;
    }
    .form-check {
        a {
            color: $primary;
            text-decoration: none;
        }
    }
}
.form-control {
    background: $input-background;
    border-radius: 5px;
    border: 0;
    color: $input-color;
}
.form-select {
    color: $mid-grey;
    border-radius: 2px;
    font-size: 0.75rem;
    @include media-breakpoint-up(md) {
        border-radius: 3px;
        font-size: 1rem;
    }
}
.filter {
    .form-select {
        color: $light-grey;
        background: #5f5e69;
        border: 0;
        -webkit-box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.75);
    }
}