// breakpoints

$MS:    375px;
$S:     576px;
$M:     768px;
$L:     992px;
$XL:    1200px;
$XXL:   1500px;

// media queries

@mixin MQ($canvas) {
  @if $canvas == MS {
   @media only screen and (min-width: $MS) { @content; }
  }
  @else if $canvas == S {
   @media only screen and (min-width: $S) { @content; }
  }
  @else if $canvas == M {
   @media only screen and (min-width: $M) { @content; }
  }
  @else if $canvas == L {
   @media only screen and (min-width: $L) { @content; }
  }
  @else if $canvas == XL {
   @media only screen and (min-width: $XL) { @content; }
  }
  @else if $canvas == XXL {
   @media only screen and (min-width: $XXL) { @content; }
  }
}

// super light grid system

@mixin column($percentage, $float-direction:left) {
  width: 100% * $percentage;
  float: $float-direction;
}
