.blog-copy {
    .row {
        margin: 1.5rem 0;
        &:first-of-type {
            margin-top: 0;
        }
        @include media-breakpoint-up(md) {
            margin: 3rem 0
        }
    }
    .img-caption {
        font-size: 0.75rem;
        font-style: italic;
        font-weight: 700;
        margin-top: 0.5rem;
    }
}
.blog-footer {
    padding: 1rem 0;
    border-bottom: #eee solid 1px;
    border-top: #eee solid 1px;
}