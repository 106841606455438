body {
    font-family: urw-din, sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $white;
    position: relative;
    @include media-breakpoint-up(sm) {
        font-size: 1rem !important;
    }
}
@mixin font-light {
    font-family: urw-din, sans-serif;
    font-weight: 300;
}
@mixin font-reg {
    font-family: urw-din, sans-serif;
    font-weight: 400;
}
@mixin font-bold {
    font-family: urw-din, sans-serif;
    font-weight: 700;
}
@mixin small-caps {
    font-family: urw-din, sans-serif;
    font-weight: 700;
    font-size: 0.75rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}
@mixin large-text {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 6vw;
    color: $mid-grey;
    line-height: 1;
}
@mixin font-awesome {
    font-family: "Font Awesome 5 Pro";
}
@mixin hover-arrow {
    &:after {
        @include font-awesome;
        content: '\f0da';
        position: absolute;
        right: -20px;
        color: $primary;
        font-size: 1rem;
        line-height: 1;
        top: 2px;
        right: -1rem;
        transition: right 0.8s ease-out;
    }
    &:hover:after {
        right: -1.5rem;
    }
    &:hover {
        text-decoration: none;
        margin-left: 0.5rem;
    }
}
.large-text {
    @include large-text;
    &.smaller {
        font-size: 4vw;
        @include media-breakpoint-down(md) {
            font-size: 7vw;
        }
    }
}
h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
    font-weight: 700;
    margin-bottom: 1rem;
    @include media-breakpoint-up(sm) {
       // margin-bottom: 1rem;
    }
    @include media-breakpoint-up(md) {
        margin-bottom: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 2rem;
    }
}
h1,.h1 {
    font-size: 1.75rem;
    @include media-breakpoint-up(md) {
        font-size: 2rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 2.5rem;
    }
}
h2,.h2 {
    font-size: 1.5rem;
    @include media-breakpoint-up(md) {
        font-size: 1.75rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 2rem;
    }
}
h3,.h3 {
    font-size: 1.25rem;
    @include media-breakpoint-up(md) {
        font-size: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 1.75rem;
    }
}
h4,.h4 {
    font-size: 1rem;
    @include media-breakpoint-up(sm) {
        font-size: 1.063rem;
    }
    @include media-breakpoint-up(md) {
        font-size: 1.125rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
    }
}
h5,.h5 {
    @include media-breakpoint-down(lg) {
    }
    @include media-breakpoint-down(md) {
    }
    @include media-breakpoint-down(sm) {
    }
    @include media-breakpoint-down(ms) {
    }
}
h6,.h6 {
    @include media-breakpoint-down(lg) {
    }
    @include media-breakpoint-down(md) {
    }
    @include media-breakpoint-down(sm) {
    }
    @include media-breakpoint-down(ms) {
    }
}
.small {
    font-size: 0.75rem;
}
.bold {
    font-weight: 700;
}
.sub-title {
    @include small-caps;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    h1,.h1 {
        font-size: 2.25rem;
    }
}
