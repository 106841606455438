.footer {
    background: rgba($footer, 0.6);
    color: $white;
    a {
        color: $primary;
        text-decoration: none;
        transition: color 0.3s ease;
        &:hover {
            color: $white;
        }
    }
    .footer-logo {
        height: 50px;
        margin-bottom: 2rem;
        @include media-breakpoint-up(md) {
            height: auto;
            margin-bottom: 0;
        }
    }
    .footer-title {
        color: $primary;
        font-weight: 700;
        font-size: 1rem;
        margin-bottom: 1rem;
        @include media-breakpoint-up(md) {
            font-size: 1.5rem;
        }
    }
    ul.footer-list {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            margin-left: 0;
            margin-bottom: 0.5rem;
            a {
                color: $white;
                text-decoration: none;
                left: 0;
                display: inline-block;
                position: relative;
                transition: left 0.3s ease,
                            color 0.3s ease;
                &:hover {
                    left: 5px;
                    color: $primary;
                }
            }
        }
    }
}
.footer-secondary {
    background: #000;
    padding: 2rem 0;
    color: $white;
    a {
        color: $white;
        text-decoration: none;
        transition: color 0.3s ease;
        &:hover {
            color: $primary;
        }
    }
    a.rotate {
        display: inline-block;
        transform: rotate(0);
        transition: transform 0.6s ease;
        &:hover {
            transform: rotate(360deg);;
        }
        i {
            font-size: 1rem;
        }
    }
}