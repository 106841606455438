/* --------------------------------

1. Auto-Hiding Navigation - Simple

-------------------------------- */
.dropdown {
    .dropdown-menu {
        background-color: $primary;
        padding: 1rem;
        @include media-breakpoint-down(md) {
           left: inherit !important;
           right: 0;
        }
        a.dropdown-item {
            height: inherit;
            line-height: 1;
            text-align: left;
            font-size: 0.75rem;
            font-weight: 900;
            color: $text-color;
            margin: 0.25rem 0;
            padding: 0.25rem;
            &:hover {
                left: 0;
            }
        }
    }
}
.nav-social {
    position: absolute;
    bottom: 30px;
    right: 30px;
    a {
        color: $white;
        font-size: 1.5rem;
        margin-left: 1rem;
    }
}
.cd-primary-nav > ul .nav-social a {
    font-size: 1.5rem;
}
.cd-auto-hide-header {
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height-small;
    background-color: $grey;
    @include clearfix;
    /* Force Hardware Acceleration */
    transform: translateZ(0);
    will-change: transform;
    transition: background 0.3s ease,
                transform .5s;
    &.is-hidden {
        transform: translateY(-100%);
    }

    @include MQ(L) {
        height: $header-height;
    }
}

.cd-auto-hide-header .logo-wrapper,
.cd-auto-hide-header .nav-trigger {
    position: absolute;
    @include center(y);
    z-index: 3;
}

.cd-auto-hide-header .logo-wrapper {
    left: 5%;
    a, img {
        display: block;
    }
}
/** CLASS TO MAKE HEADER TRANSPARENT **/
.header-transparent {
    background: transparent;
    transition: background 0.3s ease;
}

.logo {
    height: 27px;
    transition: height 0.3s ease,
                margin 0.3s ease;
    @include media-breakpoint-up(lg) {
       height: 44px;
    }
    @include media-breakpoint-up(md) {
       height: 33px;
    }
}
.header-transparent .logo {
    height: 37px;
    @include media-breakpoint-up(sm) {
       margin-top: 5px;
    }
    @include media-breakpoint-up(lg) {
        height: 60px;
        margin-top: 36px;
        margin-left: 30px;
    }
}
.cd-auto-hide-header .nav-trigger {
    /* vertically align its content */
    display: table;
    height: 3rem;
    width: 3rem;
    padding: 0 1em;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: $primary;
    font-weight: bold;
    right: 0%;
    top: 2rem;
    span {
        /* vertically align inside parent element */
        display: table-cell;
        vertical-align: middle;
    }

    em, em::after, em::before {
        /* this is the menu icon */
        display: block;
        position: relative;
        border-radius: 3px;
        height: 4px;
        width: 28px;
        background-color: $white;
        backface-visibility: hidden;
    }

    em {
        /* this is the menu central line */
        transition: background-color .2s;
        background: transparent;
    }

    em::before, em::after {
        position: absolute;
        content: '';
        left: 0;
        transition: transform .2s;
    }

    em::before {
        /* this is the menu icon top line */
        transform: translateY(-4px);
    }

    em::after {
        /* this is the menu icon bottom line */
        transform: translateY(4px);
    }

    @include MQ(L) {
        display: none;
    }
}

.cd-auto-hide-header.nav-open .nav-trigger {
    em {
        /* transform menu icon into a 'X' icon */
        background-color: rgba($color-3, 0);
    }

    em::before {
        /* rotate top line */
        transform: rotate(-45deg);
    }

    em::after {
        /* rotate bottom line */
        transform: rotate(45deg);
    }
}

.cd-primary-nav {
    display: inline-block;
    float: right;
    height: 100%;
    padding-right: 5%;
    @include media-breakpoint-up(lg) {
        display: table;
    }
    @include media-breakpoint-down(lg) {
        padding: 8rem 10%;
        overflow-y: scroll;
        position: relative;
        width: 100%;
        height: 100vh;
        background: url(/images/nav-bg.jpg) no-repeat top;
        background-size: cover;
        transition: right 300ms ease-in-out;
        right: -100%;
    }
    @include media-breakpoint-down(ms) {
        padding-right: 0;
    }
    .telephone {
        position: absolute;
        right: 70px;
        top: 17px;
        color: $white;
        opacity: 0.5;
        font-size: 1rem;
    }
    > ul {
        z-index: 2;
        top: 0;
        transition: right 0.3s ease;
        right: 0;
        width: 100%;
        display: block;
        // box-shadow: 0 14px 20px rgba(#000, .2);
        margin: 0;
        @include media-breakpoint-down(xl) {
            li {
                margin-right: 2rem;
            }
        }
        @include media-breakpoint-up(lg) {
            /* vertically align inside parent element */
            // display: table-cell;

            vertical-align: middle;
            /* reset mobile style */
            position: relative;
            width: auto;
            top: 0;
            padding: 30px 0 0 0;
            @include clearfix;
            background-color: transparent;
            box-shadow: none;
        }
        @include media-breakpoint-down(lg) {
            li {
                margin-right: 1rem;
                a {
                    padding-left: 0;
                    font-size: 0.875rem;
                }
            }
        }
        @include media-breakpoint-down(lg) {
            &:after {
                content: '';
                //position: absolute;
                left: 0;
                bottom: 0;
                z-index: 5;
                right: 0;
                opacity: 0.8;
                pointer-events: none;
            }
        }
        @include media-breakpoint-down(ms) {
           padding: 0 2rem;
        }
        li {
            list-style: none;
            @include media-breakpoint-up(lg) {
               display: inline-block;
               float: left;
               margin-right: 1rem;
               margin-left: 1rem;

               &:last-of-type {
                   margin-right: 0;
               }
            }
            @include media-breakpoint-down(lg) {
               margin-right: 0.75rem;
               margin-left: 0.75rem;
            }
            @include media-breakpoint-down(md) {
               color: $primary;
               font-size: 2rem;
               margin-left: 0;
               margin-right: 0;
            }
            a {
                /* target primary-nav links */
                display: block;
                height: inherit;
                font-size: 1.25rem;
                line-height: 1.7;
                padding-left: 0;
                color: $white;
                text-align: left;
                left: 0;
                position: relative;
                text-decoration: none;
                transition: left 0.3s ease,
                            color 0.3s ease;
                &:hover, &.active {
                    color: $primary;
                    left: 5px;
                    text-decoration: none;
                }
                @include media-breakpoint-up(sm) {
                   font-size: 2rem;
                }
                @include media-breakpoint-up(lg) {
                   /* reset mobile style */
                   line-height: normal;
                   height: auto;
                   padding: 0;
                   border: none;
                   font-size: 1rem;
                   font-weight: 500;
                   padding-left: 1rem;
                }
                @include media-breakpoint-up(xl) {
                   font-size: 1.25rem;
                }
            }

        }
        li.address {
            color: $white;
            font-size: 1rem;
            line-height: 1.7;
            font-weight: 500;
            margin-top: 3rem;
            @include media-breakpoint-up(sm) {
               font-size: 1.25rem;
            }
            a {
                font-size: 1rem;
                color: $white;
                display: inline-block;
                height: inherit;
                line-height: inherit;
                padding-left: 0;
            }
        }

    }
}
.btn-search {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0;
    background: #000;
    height: 80px;
    width: 80px;
    &:hover {
        transform: scale(1);
    }
}
.search {
    .form-wrapper {
        background: $grey;
        border-radius: 50px;
        border-right: none;
        padding: 0 20px;
        top: 0;
        margin: 2rem 0;
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $white;
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $white;
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
          color: $white;
        }
        .btn-default {
            line-height: 0;
            &:after {
                display: none;
            }
        }
        .portland-form {
            .form-title {
                color: $secondary;
            }
            .form-group {
                margin: 0;
                .form-control {
                    border: 0;
                    height: calc(1.6em + 1rem + 12px);
                    color: $white;
                    margin: 0;
                    padding: 0;
                    font-size: 1rem;
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    }
    .btn {
        position: static;
        color: $white;
    }
    .btn-default {
        font-size: 1rem;
    }
}
.nav-open .cd-primary-nav ul,
.cd-primary-nav {
    /*
        show primary nav - mobile only
        :target is used to show navigation on no-js devices
    */
    // display: block;
    // right: 0;
    // @include MQ(L) {
    //     display: table-cell;
    // }
}

header.nav-open {
    .cd-primary-nav {
        display: block;
        right: 0
    }
}

/* --------------------------------

2. Auto-Hiding Navigation - with Sub Nav

-------------------------------- */

.cd-secondary-nav {
    @include grad-dark;
    position: relative;
    z-index: 2;
    clear: both;
    width: 100%;
    height: $secondary-nav-height-small;
    /* Force Hardware Acceleration */
    transform: translateZ(0);
    will-change: transform;
    transition: transform .5s;

    &::after {
        /* gradient on the right - to indicate it's possible to scroll */
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        height: 100%;
        width: 35px;
        background: transparent;
        background: linear-gradient(to left, $color-1, rgba($color-1, 0));
        pointer-events: none;
        transition: opacity .2s;
    }

    &.nav-end::after {
        opacity: 0;
    }

    ul, li, a {
        height: 100%;
    }

    ul {
        /* enables a flex context for all its direct children */
        display: flex;
        padding: 0 5%;
        @include clearfix;
        flex-direction: column;
        overflow-y: hidden;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }

    li {
        display: inline-block;
        float: left;
        /* do not shrink - elements float on the right of the element */
        flex-shrink: 0;

        &:last-of-type {
            padding-right: 20px;
        }
    }

    a {
        display: block;
        color: $color-3;
        opacity: .6;
        line-height: $secondary-nav-height-small;
        padding: 0 1em;
        text-transform: uppercase;
        text-decoration: none;
        left: 0;
        position: relative;
        transition: left 0.3s ease,
                    opacity 0.3s ease;
        &:hover, &.active {
            opacity: 1;
            left: 5px;
        }
    }
    @include media-breakpoint-up(lg) {
        height: $secondary-nav-height;
        overflow: visible;

        ul {
            /* reset mobile style */
            display: block;
        }

        li {
            /* reset mobile style */
            float: none;
            flex-shrink: 1;
        }

        a {
            line-height: $secondary-nav-height;
            &.active {
                box-shadow: inset 0 -3px $primary;
                border-radius: 0;
                background-color: transparent !important;
            }
        }
    }
}

/* --------------------------------

3. Auto-Hiding Navigation - with Sub Nav + Hero Image

-------------------------------- */

.cd-secondary-nav {
    &.fixed {
        position: fixed;
        top: $header-height-small;
    }

    &.slide-up {
        transform: translateY(-$header-height-small)
    }

    @include MQ(L) {

        &.fixed {
            top: $header-height;
            /* fixes a bug where nav and subnab move with a slight delay */
        }

        &.slide-up {
            transform: translateY(-$header-height);
        }
    }
}


/* --------------------------------

Main content

-------------------------------- */

.cd-main-content {
    overflow: hidden;

    &.sub-nav {
        /* to be used if there is sub nav */
        // padding-top: $header-height-small + $secondary-nav-height-small;
    }

    &.sub-nav-hero {
        /* to be used if there is hero image + subnav */
        padding-top: 0;

        &.secondary-nav-fixed {
            margin-top: $secondary-nav-height-small;
        }
    }
    @include media-breakpoint-up(lg) {
        // padding-top: $header-height;

        &.sub-nav {
            padding-top: $header-height + $secondary-nav-height;
        }

        &.sub-nav-hero.secondary-nav-fixed {
            //margin-top: $secondary-nav-height;
        }
    }
}


/*
    adjust the positioning of in-page links
    http://nicolasgallagher.com/jump-links-and-viewport-positioning/
*/

.cd-main-content.sub-nav :target::before,
.cd-main-content.sub-nav-hero :target::before {
    display: block;
    content: "";
    margin-top: 0;
    height: 0;
    visibility: hidden;
    @include media-breakpoint-up(lg) {
        margin-top: 0;
        height: 0;
    }
}
/** If you want to add an extra bit on to copensate for secondary nav **/
// .cd-main-content.sub-nav :target::before,
// .cd-main-content.sub-nav-hero :target::before {
//     display: block;
//     content: "";
//     margin-top: -$secondary-nav-height-small;
//     height: $secondary-nav-height-small;
//     visibility: hidden;
//     @include media-breakpoint-up(lg) {
//         margin-top: -$secondary-nav-height;
//         height: $secondary-nav-height;
//     }
// }
/* --------------------------------

Intro Section

-------------------------------- */

.cd-hero {
    /* vertically align its content */
    background-size: cover;
    display: table;
    margin-top: 0;
    padding-bottom: 4rem;
    padding-top: calc(4rem + 60px); // Added due to fixed header overlapping.
    position: relative;
    width: 100%;
    .cd-hero-content {
        /* vertically align inside parent element */
        color: $white;
        display: table-cell;
        vertical-align: middle;
    }

    @include media-breakpoint-up(md) {
        padding-bottom: 8rem;
        padding-top: calc(8rem + 60px); // Added due to fixed header overlapping.
    }

    @include media-breakpoint-up(lg) {
        padding-top: calc(8rem + 80px); // Added due to fixed header overlapping.
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .header-transparent .logo{
        margin-left: 0;
    }
}