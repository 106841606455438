.blog-list {
    .blog-list-item.card {
        margin-bottom: 2rem;
        border-radius: 5px;
        .card-img, .card-img-top {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        .card-body {
            padding: 3rem 1rem 1rem;
            @include media-breakpoint-up(md) {
                padding: 2rem;
            }
        }
    }
}
.blog-list-item.card {
    .card-img-wrapper {
        position: relative;
        &:after {
            content: '';
            background: rgb(0,0,0);
            background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 30%, rgba(0,0,0,0.7399334733893557) 100%);
            background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 30%, rgba(0,0,0,0.7399334733893557) 100%);
            background: linear-gradient(180deg, rgba(0,0,0,0) 30%, rgba(0,0,0,0.7399334733893557) 100%);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

        }
    }
    .card-body {
        padding: $blog-item-padding;
        position: relative;
        .card-title {
            margin-top: 1rem;
        }
    }
}
.blog-details {
    margin-top: -9%;
    font-size: 0.5rem;
    color: $white;
    @include media-breakpoint-up(sm) {
        font-size: 0.75rem;
        margin-top: -16%;
    }
    @include media-breakpoint-down(sm) {
        margin-top: -23%;
    }
    @include media-breakpoint-up(md) {
        margin-top: -10%;
    }
    @include media-breakpoint-up(lg) {
        margin-top: -15%;
    }
    @include media-breakpoint-up(xl) {
        margin-top: -14%;
    }
    @include media-breakpoint-up(xxl) {
        margin-top: -18%;
    }
    .author {
        font-weight: 700;
        img {
            border-radius: 50px;
            width: 55px;
            height: 55px;
            object-fit: cover;
            @include media-breakpoint-up(xl) {
                width: 75px;
                height: 75px;
            }
        }
    }
}