.swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-pagination-bullet {
    background: $primary !important;
    border: 0 !important;
    width: 8px !important;
    height: 8px !important;
}

.swiper-pagination-bullet-active{
    background: transparent !important;
    width: 16px !important;
    height: 16px !important;
    border: 3px solid $primary !important;
}
.blog-swiper {
    margin-top: 3rem;
    &.swiper-container {
        padding-right: 4rem;
        padding-bottom: 40px;
        overflow: visible !important;
        @include media-breakpoint-up(sm) {
            padding-right: 0;
        }
        @include media-breakpoint-up(md) {
            overflow: hidden !important;
        }
    }
    .swiper-scrollbar {
        bottom: 0;
        background: #EBEBEB;
        height: 8px;
        border-radius: 0;
        .swiper-scrollbar-drag {
            @include grad;
            border-radius: 0;
        }
    }
    &.swiper-container-horizontal>.swiper-scrollbar {
        height: 10px;
    }
}
.full-width-gallery-swiper,
.gallery-swiper {
    &.swiper-container {
        padding-bottom: 40px;
        @include media-breakpoint-up(sm) {
            padding-right: 0;
        }
    }
    .swiper-scrollbar {
        bottom: 0;
        background: #EBEBEB;
        height: 8px;
        border-radius: 0;
        .swiper-scrollbar-drag {
            @include grad;
            border-radius: 0;
        }
    }
    &.swiper-container-horizontal>.swiper-scrollbar {
        height: 10px;
    }
}
.gallery-swiper {
    .swiper-button-prev {
        @include media-breakpoint-up(lg) {
            left: 80px;
        }
    }
}
.swiper-button-next,
.swiper-button-prev {
    width: 30px !important;
    height: 30px !important;
    background-size: 30px;
    top: 45% !important;
}
.swiper-container-rtl .swiper-button-prev:after,
.swiper-button-next:after,
.swiper-button-prev:after {
    content: ''!important;
    width: 30px;
    height: 30px;
    background-size: 30px;
}
.swiper-button-next:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='49.998' height='49.999' viewBox='0 0 49.998 49.999'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' y1='0.5' x2='1' y2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23eb6608'/%3E%3Cstop offset='1' stop-color='%23d10a11'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_516' data-name='Group 516' transform='translate(-1630.001 -194.001)'%3E%3Cpath id='Path_610' data-name='Path 610' d='M300.365,19.669a25,25,0,1,0,25,25A25,25,0,0,0,300.365,19.669Zm0,40a15,15,0,1,1,15-15A15,15,0,0,1,300.365,59.668Z' transform='translate(1354.635 174.332)' fill='url(%23linear-gradient)'/%3E%3Cg id='Group_514' data-name='Group 514' transform='translate(1661.149 227.433) rotate(180)'%3E%3Crect id='Rectangle_906' data-name='Rectangle 906' width='12.462' height='3.116' transform='translate(0 8.812) rotate(-45)' fill='%23f36f21'/%3E%3Crect id='Rectangle_907' data-name='Rectangle 907' width='12.462' height='3.116' transform='translate(2.203 6.621) rotate(45)' fill='%23f36f21'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}
.swiper-button-prev:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='49.998' height='49.999' viewBox='0 0 49.998 49.999'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' y1='0.5' x2='1' y2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23eb6608'/%3E%3Cstop offset='1' stop-color='%23d10a11'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_517' data-name='Group 517' transform='translate(1679.998 244) rotate(180)'%3E%3Cpath id='Path_610' data-name='Path 610' d='M300.365,19.669a25,25,0,1,0,25,25A25,25,0,0,0,300.365,19.669Zm0,40a15,15,0,1,1,15-15A15,15,0,0,1,300.365,59.668Z' transform='translate(1354.635 174.332)' fill='url(%23linear-gradient)'/%3E%3Cg id='Group_514' data-name='Group 514' transform='translate(1661.149 227.433) rotate(180)'%3E%3Crect id='Rectangle_906' data-name='Rectangle 906' width='12.462' height='3.116' transform='translate(0 8.812) rotate(-45)' fill='%23f36f21'/%3E%3Crect id='Rectangle_907' data-name='Rectangle 907' width='12.462' height='3.116' transform='translate(2.203 6.621) rotate(45)' fill='%23f36f21'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}
.full-width-gallery-swiper .swiper-button-next {
    @include media-breakpoint-up(lg) {
        right: 120px;
    }
}
.full-width-gallery-swiper .swiper-button-prev {
    @include media-breakpoint-up(lg) {
        left: 120px;
    }
}
.book-swiper {
    margin-top: 3rem;
    overflow: hidden;
    &.swiper-container {
        padding-bottom: 40px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 60px;
        }
    }
    .book-list-item {
        img {
            height: auto;
            object-fit: contain;
            object-position: top;
        }
        .review {
            font-style: italic;
            font-size: 1rem;
            @include media-breakpoint-up(md) {
                font-size: 1.25rem;
            }

            .source {
                font-style: normal;
                font-weight: 600;
            }
        }
    }
}
.books-slider-prev {
    left: 10px !important;
    top: auto !important;
    @include media-breakpoint-up(lg) {
        left: 75px !important;
    }
}
.books-slider-next {
    top: auto !important;
}

.books-slider-next,
.books-slider-prev {
    bottom: 5px !important;
    z-index: 11 !important;
}