.casestudy-tabs .tab-content {
    background: $grey;
}
.casestudy-tabs {
    .block {
        padding-bottom: 7rem !important;
    }
}
.case-study-bg {
    position: relative;
    @include media-breakpoint-down(lg) {
        background: transparent !important;
    }
    &:before {
        content: '';
        background: rgba($black, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        @include media-breakpoint-down(lg) {
            background: rgb(0,0,0);
            background: -moz-linear-gradient(0deg, rgba(0,0,0,0) 74%, rgba(0,0,0,0.7399334733893557) 100%);
            background: -webkit-linear-gradient(0deg, rgba(0,0,0,0) 74%, rgba(0,0,0,0.7399334733893557) 100%);
            background: linear-gradient(0deg, rgba(0,0,0,0) 74%, rgba(0,0,0,0.7399334733893557) 100%);
        }
    }
    .testimonial-wrapper {
        position: absolute;
        bottom: 10%;
        left: 10%;
        width: 30%;
        @include media-breakpoint-down(lg) {
            @include grad;
            position: static;
            width: 100%;
            padding: 5rem;
        }
        @include media-breakpoint-down(sm) {
            padding: 3.5rem;
        }
        .testimonial {
            color: $white;
            position: relative;
            @include media-breakpoint-down(lg) {
                position: static;
            }
            .copy {
                font-size: 0.875rem;
                margin-bottom: 1rem;
                position: relative;
                &:before,
                &:after
                 {
                    content: '';
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    background-size: cover;
                }
                &:before {
                    background-image: url(/images/quote-start.svg);
                    background-repeat: no-repeat;
                    right: inherit;
                    top: -30px;
                    left: -30px;
                }
                &:after {
                    background-image: url(/images/quote-end.svg);
                    background-repeat: no-repeat;
                    left: inherit;
                    bottom: -30px;
                    right: -30px;
                }
            }
            .author {
                font-weight: 700;
                margin-bottom: 1rem;
            }
            .company {
                font-size: 0.75rem;
                font-weight: 700;
            }
        }
    }
    .powered {
        position: absolute;
        top: 3%;
        left: 10%;
        color: $white;
        @include media-breakpoint-up(md) {
            top: 5%;
        }
        @include media-breakpoint-up(lg) {
            top: 10%;
        }
    }
}
.service-block,
.case-study-block {
    @include grad-mid-trans;
    padding: 5rem;
    color: $white;
    position: relative;
    z-index: 2;
    .snippet {
        font-size: 0.875rem;
        margin-bottom: 1rem;
    }
    @include media-breakpoint-down(lg) {
        margin-bottom: 0;
        @include grad-mid;
    }
    @include media-breakpoint-down(sm) {
        padding: 2rem;
    }
}
.service-block {
    @include grad-dark-trans;
    margin-bottom: 3rem;
    h4 {
        color: $primary;
    }
    @include media-breakpoint-down(lg) {
        margin-bottom: 0;
        @include grad-dark;
    }
}
.testimonial-wrapper {
    position: static;
    width: 100%;
    .testimonial {
        color: $white;
        position: relative;
        @include media-breakpoint-down(lg) {
            position: static;
        }
        .copy {
            margin-bottom: 1rem;
            position: relative;
            &:before,
            &:after
             {
                content: '';
                position: absolute;
                width: 30px;
                height: 30px;
                background-size: 30px;
            }
            &:before {
                background-image: url(/images/quote-start.svg);
                background-repeat: no-repeat;
                right: inherit;
                top: -30px;
                left: -30px;
            }
            &:after {
                background-image: url(/images/quote-end.svg);
                background-repeat: no-repeat;
                left: inherit;
                bottom: -30px;
                right: -30px;
            }
        }
        .author {
            font-weight: 700;
            margin-bottom: 1rem;
        }
        .company {
            font-size: 0.75rem;
            font-weight: 700;
        }
    }
}
.text-img {
    margin-bottom: 0rem;
    &:last-of-type {
        margin-bottom: 0;
    }
    @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 5rem;
    }
    .text-overlap {
        position: static;
        color: $white;
        z-index: 0;
        padding-top: 1.5rem;

        @include media-breakpoint-up(lg) {
            padding-top: 0;
            z-index: 3;
        }
        h2 {
            font-size: 0.875rem;
            line-height: 1.5;
            @include media-breakpoint-up(md) {
                font-size: 1.25rem;
            }
            @include media-breakpoint-up(xxl) {
                font-size: 1.75rem;
            }
        }
    }
    .item-img {
        height: 45vw;
        position: relative;
        @include media-breakpoint-up(md) {
            height: 25vw;
        }
        @include media-breakpoint-up(lg) {
            //position: absolute;
            right: 0;
        }
        &:after {
            content: '';
            background: rgba($grey, 0.6);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
        }
        &.red {
            &:after {
            content: '';
            background: rgba($primary, 0.6);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
            }
        }
    }
}
.text-img .item-img.none:after {
    background: transparent;
}


.case-study-list-section .text-img:nth-of-type(even) .item-img:after {
    // background: rgba($primary, 0.6);
}
.case-study-list-section {
    padding-top: 0 !important;
    
    .sub-title {
        margin-bottom: 0.5em;
        color: $primary;
        font-size: 0.75rem;

        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }
    }
}
.cs-list-logo {
    position: absolute;
    bottom: 50px;
    right: 50px;
    z-index: 3;
    @include media-breakpoint-down(md) {
        bottom: 30px;
        right: 30px;
    }
    @include media-breakpoint-down(ms) {
        bottom: 20px;
        right: 20px;
    }
}
.cs-logo {
    max-height: 50px;
    height: 50px;
    @include media-breakpoint-up(md) {
        max-height: 100px;
        height: 100px;
    }
}
.stat {
    border-left: 2px solid #fff;
    padding-left: 1rem;
    margin-top: 2rem;
    @include media-breakpoint-up(sm) {
        padding-left: 2rem;
    }
    .sub-title {
        @include small-caps;
        font-size: 0.75rem;
        @include media-breakpoint-up(sm) {
            font-size: 1rem;
        }
    }
    .number {
        font-weight: 700;
        font-size: 2rem;
        @include media-breakpoint-up(sm) {
            font-size: 3rem;
        }
    }
}
.case-study-card-wrapper {
    margin-top: -3rem;
    padding-bottom: 3rem;
    .case-study-cards {
        .card {
            @include grad-dark;
            border-radius: 5px;
            border: 0;
            .card-header {
                @include grad;
                border-radius: 5px 5px 0 0;
                color: $white;
                font-weight: 700;
                font-size: 1rem;
                padding: .5rem 1rem;
                text-align: center;
                @include media-breakpoint-up(md) {
                    font-size: 1.25rem;
                }
            }
            .card-img-top {
                border-radius: 0px;
                height: 200px;
                object-fit: cover;
            }
            .card-body {
                border-radius: 0 0 5px 5px;
                font-size: 0.75rem;
                padding: 1rem;
                @include media-breakpoint-up(md) {
                    font-size: 0.9rem;
                }
                ul {
                    padding-left: 1rem;
                }
                li, p {
                    font-size: 0.75rem;
                    @include media-breakpoint-up(md) {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }
}

.case-study {
    & + & {
        border-top: 2px solid rgba($primary, 0.75);
        padding-top: 4.5rem;
    }
}