.btn-default {
    @include hover-arrow;
    padding: 0;
    @include small-caps;
    position: relative;
    transition: margin 0.4s ease;
    border-radius: 0;
}
button:focus {
    outline: none;
}
.btn-primary:focus, .btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active {
    background: transparent;
    color: $white;
    border-color: transparent;
}
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.btn-primary:focus,
.btn-primary.focus {
    box-shadow: none;
}
.btn-grad,
.btn-white,
.btn-secondary,
.btn-danger,
.btn-warning,
.btn-info,
.btn-primary {
    @include small-caps;
    border: 0;
    border-radius: 50px;
    background: transparent;
    color: $white;
    padding: 10px 30px;
    font-size: 0.75rem;
    transition: all 0.3s ease;
    position: relative;
    left: 0;
    z-index: 2;
    @include media-breakpoint-up(md) {
        font-size: 1rem;
        padding: 15px 40px;
    }
    &:before {
        content: url(/images/bullet.svg);
        width: 30px;
        height: 30px;
        position: absolute;
        // transform: scale(0.75);
        top: 50%;
        left: 0;
        right: inherit;
        -ms-transform: translateY(-50%) scale(0.75);
        transform: translateY(-50%) scale(0.75);
        transition: all 0.3s ease;
        z-index: -1;
        @include media-breakpoint-up(md) {
            transform: translateY(-50%) scale(1);
        }
        &:hover {
            transform: scale(1.5);
            transform-origin: center;
        }
    }
    &.no-before {
        &:before {
            display: none;
        }
    }
    &:hover {
        background: transparent;
        // transform: scale(1.1);
        left: 5px;
        color: $white;
        &:before {
            transform: translateY(-50%) scale(1.5);
            transform-origin: center;
            top: 50%;
            left: 0;
            right: 0;
            width: 100%;
        }
    }
}
.btn-secondary {
    background: $secondary-light;
}
.btn-white {
    background: $white;
    color: $primary;
}
.btn-danger {
    background-color: #ae4892;
    color: $white !important;
}
.btn-grad {
    @include grad;
}
.btn-sml {
    font-size: 0.75rem;
}
.btn-sc {
    position: relative;
    margin-left: 20px;
    top: 2px;
    line-height: 1;
    @include media-breakpoint-only(lg) {
        display: block;
        width: 100%;
        margin-top: 3rem;
    }
    @include media-breakpoint-down(sm) {
        display: block;
        width: 100%;
        margin-top: 3rem;
        margin-left: 0;
    }
}
.btn-full-width {
    width: 100%;
}
.slider-all-btn {
    margin-top: 1rem;
    // @include media-breakpoint-only(lg) {
    //     margin-top: 7vw;
    // }
}
.btn-area {
    @include media-breakpoint-down(xl) {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-top: 2rem;
    }
}
.play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 7vw;
    opacity: 0.8;
    margin-top: -15px;
    line-height: 1;
}
/***** TEXT FLIP *****/
a.rotate360 {
    display: inline-block;
    transform: rotate(0);
    transition: transform 0.6s ease;
    &:hover {
        transform: rotate(360deg);;
    }
    i {
        font-size: 1rem;
    }
}
.animation {
    outline: none;
    text-decoration: none;
    position: relative;
    font-size: 8em;
    line-height: 1;
    color: #9e9ba4;
    display: inline-block;
}
/* Nukun */
.flip {
    color: $primary;
    overflow: hidden;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}

.flip:hover {
    color: $white;
}

.flip span {
    color: $white;
    display: inline-block;
    position: relative;
    -webkit-transform: perspective(1000px) rotate3d(0,1,0,0deg);
    transform: perspective(1000px) rotate3d(0,1,0,0deg);
    -webkit-transition: -webkit-transform 0.5s, color 0.5s;
    transition: transform 0.5s, color 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.2,1,0.3,1);
    transition-timing-function: cubic-bezier(0.2,1,0.3,1);
}

.flip:hover span {
    color: $white;
    -webkit-transform: perspective(1000px) rotate3d(0,1,0,180deg);
    transform: perspective(1000px) rotate3d(0,1,0,180deg);
}

.btn {
    & > :last-child {
        margin-bottom: 0;
    }
}