.bg-grey {
    background: $grey;
    color: $white;
}
.bg-white {
    background: $white;
    color: $text-color;
    .btn,
    a {
        color: $text-color;
        &:hover {
            color: $text-color;
        }
    }
}
.bg-dark-grad {
    @include grad-dark;
    color: $white;
}
.bg-mid-grad {
    @include grad-mid;
    color: $white;
}
.bg-grey-grad {
    @include grad-grey;
    color: $text-color;
    .btn,
    a {
        color: $text-color;
        &:hover {
            color: $text-color;
        }
    }
}