html {
    scroll-behavior: smooth;
}

body {
    @include media-breakpoint-up(lg) {
        padding-left: 60px; // Stop overlap from fixed sidebar.
    }
}

.concentric-circles {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.sticky-side {
    background: rgba($dark, 0.5);
    width: 100vh;
    height: 60px;
    transform-origin: bottom left;
    position: fixed;
    left: 60px;
    bottom: 0;
    transform: rotate(-90deg);
    border-bottom: 1px solid #919092;
    z-index: 4;
    .inner {
        font: bold 12px Sans-Serif;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: rgba($white, 0.5);
        padding: 0;
        margin: 0 100px 0 30px;
        line-height: 1;
        height: 100%;
        .breadcrumb {
            margin-bottom: 0;
            justify-content: flex-end;
            .breadcrumb-item {
                a {
                    text-decoration: none;
                    color: $white;
                }
            }
        }
        ul.social {
            li {
                color: $white;
                a {
                    align-items: center;
                    display: inline-flex;
                    color: $white;
                    transition: color 0.3s ease;
                    text-decoration: none;
                    &:hover {
                        color: $primary;
                    }
                    i {
                        font-size: 1rem;
                    }

                }
                a.rotate {
                    display: inline-block;
                    transform: rotate(0);
                    color: $white;
                    transition: transform 0.3s ease,
                                color 0.3s ease;
                    &:hover {
                        transform: rotate(90deg);
                        color: $primary;
                    }
                    i {
                        font-size: 1rem;
                    }

                }
            }
        }
    }
}
blockquote {
    font-size: 1.25rem;
    font-weight: 700;
    padding: 1.5rem 0;
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;
    position: relative;
    margin-top: 2rem;
    &:after {
        content: '';
        position: absolute;
        background-image: url(/images/quote-end-primary.svg);
        background-repeat: no-repeat;
        left: inherit;
        bottom: -25px;
        right: 5px;
        width: 30px;
        height: 30px;
        background-size: 30px;
    }
    @include media-breakpoint-up(md) {
        font-size: 2rem;
        margin-top: 4rem;
    }
}
.small-caps {
    @include small-caps;
    @include media-breakpoint-down(sm) {
        font-size: 0.5rem;
    }
}
.orange-overlay {
    position: relative;
    &:after {
        content: '';
        @include grad-trans;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
.dark-overlay {
    position: relative;
    &:after {
        content: '';
        @include grad-dark-trans;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
.lead {
    margin-bottom: 1.125rem;
    font-size: 1rem;
    @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
        font-size: 1.5rem;
    }
}
.relative {
    position: relative;
}
.top-layer {
    overflow-x: hidden;
    z-index: 1;
}
// .overflow-hidden {
//     overflow: hidden
// }
.highlight {
    padding: 0 3rem 3rem;
    position: relative;
    font-size: 0.875rem;
    z-index: 2;
    @include media-breakpoint-up(sm) {
        padding: 3rem;
    }
    @include media-breakpoint-up(md) {
        padding: 11vw 5rem;
        color: $white;
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            width: 100%;
            border-radius: 0 50vw 50vw 0;
            @include grad;
            z-index: 0;
        }
    }
    @include media-breakpoint-up(xl) {
        padding: 10vw 5rem;
    }
    @include media-breakpoint-up(xxl) {
        padding: 10vw 5rem 10vw 7rem;
    }
    .inner {
        z-index: 2;
        position: relative;
        @include media-breakpoint-up(md) {
            width: 80%;
            a {
                color: $white;
            }
        }
        ul {
            margin-top: 2rem;
            li {
                font-weight: 700;
                margin-bottom: 0.5rem;
                a {
                    text-decoration: none;
                }
            }
        }
    }
}
.circle {
    position: absolute;
    left: -320px;
    margin: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    img {
        height: 320px;
    }
    @include media-breakpoint-up(sm) {
        left: -580px;
        img {
            height: auto;
        }
    }
}
.block {
    padding: 3rem 0;
    @include media-breakpoint-up(md) {
        padding: 4.5rem 0;
    }
    @include media-breakpoint-up(lg) {
        padding: 6rem 0;
    }
}
ul.albion-list {
    padding-left: 0;
    // margin: 2.5rem 0;
    @include media-breakpoint-down(md) {
        // margin: 1.5rem 0;
    }
    li {
        background: url(/images/bullet.svg) no-repeat top left;
        background-position: 0 17px;
        list-style: none;
        font-size: 0.75rem;
        padding: 10px 0 10px 60px;
        font-weight: 700;
        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }
        @include media-breakpoint-up(lg) {
            background-position: 0 15px;
            background-size: 24px;
            font-size: 1.25rem;
            padding: 10px 0 10px 40px;
            font-weight: 400;
        }
        @include media-breakpoint-down(ms) {
            background-position: 0 9px;
        }
    }
}
.service-title {
    position: relative;
    color: $primary;
    margin-top: 2rem;
    font-size: 1rem;
    font-weight: 700;
    padding-left: 40px;
    @include media-breakpoint-up(md) {
        font-size: 1.25rem;
        padding-left: 60px;
        margin-top: 4rem;
    }
    &:after {
        content: '';
        background-image: url(/images/tab-plus-active.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: inherit;
        top: 50%;
        left: 0;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        background-size: 30px;
        @include media-breakpoint-up(md) {
            width: 50px;
            height: 50px;
            background-size: 50px;
        }
    }
}
.service-pills {
    margin-top: 4rem;
    @include media-breakpoint-down(md) {
        display: block;
        margin: 0;
    }
    .nav-item {
        margin: 0 3px 0 0;
        &:nth-last-of-type(1) {
            margin-right: 0;
            @include media-breakpoint-down(md) {
                margin-right: 1rem;
            }
        }
        @include media-breakpoint-down(md) {
            display: block;
            margin: 0.5rem 1rem;
        }
        .nav-link {
            position: relative;
            border-radius: 0;
            background: $mid-grey;
            color: $white;
            font-weight: 700;
            font-size: 1rem;
            padding: .5rem 1rem;
            border-radius: 30px;
            text-align: left;
            transition: background 0.3s ease;
            padding-left: 3.5rem;
            @include media-breakpoint-up(md) {
                padding: 1.5rem 1rem;
                border-radius: 0;
                font-size: 0.875rem;
                text-align: center;
            }
            @include media-breakpoint-up(lg) {
                font-size: 1.25rem;
            }
            @include media-breakpoint-up(xl) {
                font-size: 1.5rem;
            }
            &:after {
                content: '';
                background-image: url(/images/tab-plus.svg);
                background-repeat: no-repeat;
                width: 52px;
                height: 52px;
                background-size: 52px;
                position: absolute;
                top: -26px;
                left: 50%;
                right: inherit;
                -ms-transform: translateX(-50%);
                transform: translateX(-50%);
                @include media-breakpoint-down(md) {
                    top: 50%;
                    left: 15px;
                    right: inherit;
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    width: 30px;
                    height: 30px;
                    background-size: 30px;
                }
            }
            &:hover {
                background: $grey;
            }
            &.active {
                @include grad;
                &:after {
                    content: '';
                    background-image: url(/images/tab-plus-active.svg);
                    background-repeat: no-repeat;
                    width: 52px;
                    height: 52px;
                    background-size: 52px;
                    position: absolute;
                    top: -26px;
                    left: 50%;
                    right: inherit;
                    -ms-transform: translateX(-50%);
                    transform: translateX(-50%);
                    @include media-breakpoint-down(md) {
                        top: 50%;
                        left: 15px;
                        right: inherit;
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);
                        width: 30px;
                        height: 30px;
                        background-size: 30px;
                    }
                }
                &:hover {
                    // background: $primary;
                }
            }
        }

    }
}
.smartersuccess {
    height: 14px;
    @include media-breakpoint-up(md) {
        height: 20px;
    }

    @include media-breakpoint-up(xl) {
        height: 24px;
    }
}
.social-strip {
    padding-top: 3rem;
    @include media-breakpoint-up(md) {
        padding-top: 6rem;
    }
    ul {
        position: absolute;
        top: 15px;
        @include media-breakpoint-only(md) {
            top: 30px;
        }
        @include media-breakpoint-up(lg) {
            top: 44px;
        }
        li {
            margin-right: 1rem;
            &.list-inline-item:not(:last-child) {
                margin-right: 1rem;
            }
            img {
                height: 60px;
                @include media-breakpoint-up(md) {
                    height: 100px;
                }
            }
        }
    }
    .text-big {
        color: $white;
        font-size: 1rem;
        font-weight: 700;
        padding: 1.5rem 0;
        @include media-breakpoint-up(md) {
            padding: 3rem 0;
            font-size: 1.5rem;
        }
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .sticky-side {
        display: none !important;
    }
}
.grecaptcha-badge {
    display: none;
}
ul {
    padding-left: 0;
}

ul.tinymce,
.service-content ul {
        li {
            background: url(/images/bullet.svg) no-repeat top left;
            background-position: 0 6px;
            list-style: none;
            margin-bottom: .75rem;
            padding-left: 1.5rem;
            background-size: .75rem;
            @include media-breakpoint-up(md) {
              background-size: 1rem;  
              padding-left: 2rem;
            }

            &:last-child {
                margin-bottom: 0;
            
        }
    }
}

li > ul {
    margin-top: 1rem;
}

.b-rad-service {
    border-radius: 300px;
    @include media-breakpoint-up(md) {
        border-radius: 0 300px 300px 0;
    }
}
.b-rad-service-alt {
    border-radius: 300px;
    @include media-breakpoint-up(md) {
        border-radius: 300px 0 0 300px;
    }
}
#ourFees {
    scroll-margin-top: 50px;
}
.twitter-embed {
    max-height: 650px;
    overflow: scroll;
    border-radius: 10px;
}
.contact-img {
    img {
        height: 400px;
        object-fit: cover;
        position: relative;
        z-index: 10;
        @include media-breakpoint-down(lg) {
            height: 250px;
        }
        @include media-breakpoint-down(md) {
            height: 200px;
        }
        @include media-breakpoint-down(sm) {
            height: 150px;
        }
    }
}